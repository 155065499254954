import React from "react";
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import { CategoryGrid } from '../components/CategoryGrid';
import { graphql } from 'gatsby';
import Seo from "../components/Seo";

class CaregoryType extends React.Component {
    constructor(props) {
      super();

      const allCategories = props.data.allCategoryGroups.edges.concat(props.data.allCategories.edges);
      const allCovers = props.data.coversCategoryGroups.edges.concat(props.data.coversCategories.edges);

      this.data = allCategories.map((categoryType) => {
          let item = {};
          item.slug = categoryType.node.categoryGroup === "hidden" ? categoryType.node.data.slug : categoryType.node.categoryGroupSlug;
          item.name = categoryType.node.categoryGroup === "hidden" ? categoryType.node.data.name : categoryType.node.categoryGroupName;
          item.order = categoryType.node.categoryGroup === "hidden" ? categoryType.node.data.order : categoryType.node.categoryGroupOrder;
          item.cover = {};
          for(let i = 0; i < allCovers.length; i++) {
              const currentItem = allCovers[i].node;
              if(currentItem.fields.data.type === categoryType.node.type && ((currentItem.fields.data.categoryGroup !== "hidden" && currentItem.fields.data.categoryGroup === categoryType.node.categoryGroup) || (currentItem.fields.data.categoryGroup === "hidden" && currentItem.fields.data.category === categoryType.node.category))) {
                  item.cover.original = currentItem.original;
                  item.cover.fluid = currentItem.fluid;
              }
          }
          return item;
      });
    }

    render() {


        let crumbs = [this.props.pageContext.typeName];



        return (
            <Layout location={this.props.location}>
                <Seo pathname={this.props.path} context={{type:this.props.pageContext.type}} />
                <div style={{paddingTop:"50px"}}>
                    <Section crumbs={crumbs} />
                    <CategoryGrid  data={this.data}/>
                </div>
            </Layout>
        )
    }
}

export default CaregoryType;

export const pageQuery = graphql`
query CaregoryTypeAutoPage($type: String) {
  coversCategoryGroups: allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, type: {eq: $type}, isCategoryGroupCover: {eq: true}, categoryGroup: {ne: "hidden"}}}}, sort: {fields: [fields___data___category], order: ASC}) {
    edges {
      node {
        ...CategoryGridCovers
      }
    }
  }
  coversCategories: allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "pictures"}, type: {eq: $type}, isCategoryCover: {eq: true}, categoryGroup: {eq: "hidden"}}}}, sort: {fields: [fields___data___category], order: ASC}) {
    edges {
      node {
        ...CategoryGridCovers
      }
    }
  }
  
  allCategoryGroups(sort: {fields: [categoryGroupOrder], order: ASC}, filter: {type: {eq: $type}, categoryGroup: {ne:"hidden"}}) {
    edges {
      node {
        id
        type
        categoryGroup
        categoryGroupName
        categoryGroupSlug
        categoryGroupOrder
      }
    }
  }
  allCategories(sort: {fields: data___order, order: ASC}, filter: {type: {eq: $type}, categoryGroup: {eq:"hidden"}}) {
    edges {
      node {
        data {
          slug
          name
          order
        }
        id
        type
        category
        categoryGroup
      }
    }
  }
}

`;